@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.head {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  background-color: #f4b0d5;
  .bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  .feature {
    margin-top: -90px;
    position: relative;
    .after {
      content: "";
      position: absolute;
      right: 45%;
      width: 90px;
      height: 126px;
      bottom: 30px;
      background-size: contain;
      background-position: bottom center;
      z-index: 2;
    }
    &.auto {
      height: auto;
    }
    img {
      object-position: bottom;
      height: 100%;
      width: 100%;
      object-fit: cover;
      position: relative;
      z-index: 1;
    }
    .image {
      display: block;
      z-index: 1;
      margin: 0 auto;
    }
  }
  .content {
    padding: 0 12px 30px;
    text-align: center;
    z-index: 3;
    position: relative;
    z-index: 2;
    .action {
      max-width: 300px;
      margin: auto;
      margin-top: 20px;
      display: flex;
      justify-content: center;
      a {
        margin: 0 10px;
      }
    }
    .title {
      font-family: variable.$CaveatBrush;
      color: variable.$primaryColor;
      font-size: 10.65vw;
      padding-top: 24px;
    }
    .subtitle {
      font-family: variable.$primaryFontBold;
      color: variable.$primaryColor;
      font-size: 5vw;
      margin-bottom: 4vw;
      padding-inline: 25px;
    }
    .description {
      font-family: variable.$primaryFontSemiBold;
      color: variable.$whiteColor;
      font-size: 3.9vw;
      line-height: 7vw;
      font-weight: 700;
      max-width: 91vw;
      margin: 0 auto;
      text-shadow: 0 0 40px variable.$primaryColor;
      li {
        padding-left: 25px;
        background-repeat: no-repeat;
        background-position: 0;
        background-image: url("/static/images/icons/check-circle-white.png");
        background-size: auto 19px;
        margin-bottom: 5px;
        text-align: left;
        min-height: 24px;
        font-family: variable.$primaryFontSemiBold;
        font-size: 4vw;
      }
    }
  }
}

@include mixin.mediaSmallTabletSize() {
  .head {
    .feature {
      .after {
        bottom: 45px;
      }
    }
  }
}
@include mixin.mediaTabletSize() {
  .head {
    padding: 45px 0 55px;
    margin-top: 0;
    .feature {
      display: flex;
      height: 100%;
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 1;
      margin-top: 0;
      .after {
        right: calc(50% + 230px);
        width: 130px;
        height: 180px;
        bottom: 0;
      }
      &.auto {
        height: 100%;
      }
      .desktop {
        display: block;
      }
      .mobile {
        display: none;
      }
      img {
        object-position: top;
        max-width: 52%;
        object-fit: contain;
        object-position: bottom;
        height: auto;
      }
    }
    .content {
      background: transparent;
      max-width: 510px;
      margin: 0 0 0 auto;
      padding: 0;
      .title {
        font-size: 45px;
        p {
          padding-bottom: 10px;
          padding-right: 35px;
          background-repeat: no-repeat;
          background-position:
            117px bottom,
            right 4px;
          background-size:
            200px auto,
            22px auto;
        }
      }
      .subtitle {
        font-size: variable.$size19;
        margin-bottom: variable.$size24;
      }
      .description {
        font-size: variable.$size16;
        margin-bottom: variable.$size20;
        line-height: variable.$size26;
        max-width: 100%;
        ul {
          max-width: 380px;
          margin: 0 auto;
          li {
            font-size: 16.5px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

@include mixin.mediaDesktopSize() {
  .head {
    padding: 0 0 96px;
    min-height: 535px;
    justify-content: center;
    .feature {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-left: 20px solid white;
      .after {
        right: calc(50% + 330px);
        bottom: 65px;
      }
      img {
        width: 45%;
        max-width: 530px;
      }
    }
    .content {
      margin: 0 auto;
      max-width: 600px;
      .title {
        margin-bottom: 0;
        padding-top: 0;
        line-height: 55px;
        font-size: 64px;
      }
      .subtitle {
        font-size: variable.$size24;
        padding-inline: 0;
      }
      .description {
        ul {
          li {
            margin-bottom: 6px;
          }
        }
      }
    }
  }
}
@include mixin.mediaDesktop() {
  .head {
    .feature {
      background-position: top right;
    }
    .content {
      transform: none;
    }
  }
}
@include mixin.mediaLaptopSize() {
  .head {
    padding: 40px 0 20px;
    .feature {
      img {
        object-position: bottom left;
      }
    }
    .content {
      .title {
        margin-bottom: 20px;
      }
      .description {
        ul {
          li {
            margin-bottom: 13px;
          }
        }
      }
    }
  }
}
