@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.head {
  position: relative;
  background-color: #ffcfe5;
  overflow: hidden;
  @for $i from 1 through 3 {
    &.head-#{$i} {
      .feature img:nth-child(#{$i}) {
        opacity: 1;
      }
      .content .description li:nth-child(#{$i}) {
        display: block;
      }
    }
  }
  .feature {
    position: absolute;
    bottom: 0;
    padding-right: 0;
    margin: auto;
    left: 0;
    right: 0;
    padding-top: 97%;
    img {
      position: absolute;
      bottom: 0;
      opacity: 0;
      transition: all 0.5s ease;
    }
  }
  .content {
    padding: 23px 12px 20px;
    text-align: center;
    z-index: 3;
    position: relative;
    .action {
      max-width: 380px;
      margin: auto;
      padding-top: 89%;
      display: flex;
      justify-content: center;
      a {
        margin: 0 10px;
        box-shadow: 2px 2px 6px 1px rgb(0 0 0 / 21%);
      }
    }
    .title {
      font-family: variable.$titlesFont;
      color: variable.$orangeColor;
      font-size: 10.65vw;
      max-width: 63%;
      margin: auto;
      margin-bottom: 10px;
      p {
        position: relative;
        display: inline-block;
        background-image: url("/static/images/icons/title-vector.png"),
          url("/static/images/icons/title-vector-right.png");
        background-position:
          right bottom,
          center right;
        padding-bottom: 2vw;
        padding-right: 7vw;
        background-repeat: no-repeat;
        background-position:
          right bottom 4px,
          right bottom 18px;
        background-size:
          56vw 9px,
          5vw auto;
      }
    }
    .subtitle {
      font-family: variable.$primaryFontBold;
      color: variable.$primaryColor;
      font-size: 4.8vw;
      margin-bottom: 2vw;
      padding: 0 5%;
      letter-spacing: 0.9px;
    }
    .description {
      font-family: variable.$primaryFontSemiBold;
      color: variable.$primaryColor;
      font-size: 4vw;
      line-height: 7vw;
      font-weight: 700;
      max-width: max-content;
      margin: 0 auto;
      min-height: 30px;
      li {
        padding-left: 33px;
        background-repeat: no-repeat;
        background-position: 0;
        background-image: url("/static/images/icons/picto-list.png");
        background-size: auto 24px;
        text-align: left;
        min-height: 24px;
        font-family: variable.$primaryFontSemiBold;
        display: none;
      }
    }
  }
}

@include mixin.mediaTabletSize() {
  .head {
    .feature {
      left: -30px;
      right: auto;
      max-width: 45%;
      bottom: 0;
      top: 0;
      display: flex;
      align-items: flex-end;
      width: 100%;
      padding-top: 35%;

      img {
        max-height: 100%;
        width: auto;
        &:first-child {
          transform: scale(-1, 1);
        }
      }
    }
    .content {
      background: transparent;
      max-width: 510px;
      margin: 0 0 0 auto;
      padding: 20px 0;
      .action {
        padding-top: 33px;
      }
      .title {
        font-size: 45px;
        margin-bottom: 30px;
        max-width: 77%;
        p {
          padding-bottom: 10px;
          padding-right: 35px;
          background-repeat: no-repeat;
          background-position:
            117px bottom,
            right 4px;
          background-size:
            200px auto,
            22px auto;
        }
      }
      .subtitle {
        font-size: variable.$size19;
        margin-bottom: variable.$size24;
      }
      .description {
        font-size: variable.$size16;
        margin-bottom: variable.$size20;
        line-height: variable.$size26;
        max-width: 100%;
        ul {
          max-width: 380px;
          margin: 0 auto;
          li {
            font-size: 16.5px;
            margin-bottom: variable.$size20;
          }
        }
      }
    }
  }
}
@include mixin.mediaDesktopSize() {
  .head {
    padding: 45.5px 0 30px;
    .feature {
      left: auto;
      right: 0;
      justify-content: flex-end;
      img {
        height: 100%;
        &:first-child {
          transform: none;
        }
      }
    }
    .content {
      margin: 0;
      .description {
        li {
          display: block;
        }
      }
      .action {
        max-width: 470px;
      }
    }
  }
}
@include mixin.mediaDesktop() {
  .head {
    background-position: right center;
    padding: 60px 0 35px;
    .feature {
      padding-top: 0;
      height: 100%;
      width: 535px;
      img {
        height: 100%;
      }
    }
    .content {
      .subtitle {
        padding: 0;
      }
    }
  }
}
@include mixin.mediaLaptopSize() {
  .head {
    .content {
      .title {
        margin-bottom: 20px;
      }
      .description {
        ul {
          li {
            margin-bottom: 13px;
          }
        }
      }
    }
  }
}
